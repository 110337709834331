import * as React from 'react';
import {
  Typography,
  Grid,
  Button as MuiButton,
  IconButton,
  InputAdornment,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useNavigate, useLocation, NavLink } from 'react-router-dom';

import { useTranslation } from 'components/providers/TranslationProvider';
import { useAuth } from 'components/providers/AuthProvider';
import { TextField } from 'components/common/ReactHooksFormFields';
import Button from 'components/common/Button';
import Toast from 'components/common/Toast';
import {
  containerButtonsError,
  containerButtonsStyle,
  loginField, MuiButtonForgot, MuiButtonSubmit,
  MuiDivPaper, MuiGridEmail, MuiLoginGrid,
  MuiLoginSubtitle,
  MuiLoginText,
  orStyle,
  orTextStyle,
  socialButton,
  socialIcon,
  errorMessage,
} from './styles/login';
import CircularProgress from "@mui/material/CircularProgress";
import { providerCodes } from '../../../utils/constants';
import {MdLockOutline, MdOutlinePerson} from "react-icons/md";

const schema = yup.object().shape({
  email: yup.string().email('email_required').required('required'),
  password: yup.string().min(4, 'min_4_characters').required('required'),
});

const actionTypes = {
  SUBMIT_FORM: 'submit_form',
  LOGIN_SOCIAL_GOOGLE: 'login_social_google',
  LOGIN_SOCIAL_MICROSOFT: 'login_social_microsoft'
}

const Login = () => {
  const [error, setError] = React.useState('');
  const [redirectURL, setRedirectURL] = React.useState('/orgs');
  const { t, lng } = useTranslation();
  const [showPassword, setShowPassword] = React.useState(false);
  const [actionTaken, setActionTaken] = React.useState('');

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const { login, isPending, loginWithSocial, openDialog, setOpenDialog } = useAuth();

  const location = useLocation();
  const navigate = useNavigate();

  React.useEffect(() => {
    const currentPath = location.pathname;
    if (currentPath !== '/login') {
      setRedirectURL(currentPath);
      navigate('/login', { replace: true });
    }
    // eslint-disable-next-line
  }, []);

  const methods = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
  });

  const {
    handleSubmit,
    formState: { errors, isValid },
  } = methods;

  const handleError = (e) => {
    setError(t(e)?.replace('{{forgot_password_key}}', t('forgot_password')));
  };

  const onSubmit = (data) => {
    setActionTaken(actionTypes.SUBMIT_FORM);
    return login(data, redirectURL, handleError)
  };
  const loginSocial = (provider) => loginWithSocial(provider, redirectURL, handleError);
  const handleClose = () => {
    setOpenDialog(false)
  };

  React.useEffect(() => {
    if( error ) {
      setError(t('incluia_authentication_error')?.replace('{{forgot_password_key}}', t('forgot_password')));
    }
  }, [lng]);

  return (
    <MuiDivPaper>
      <MuiLoginText>
        Inclu<span style={{fontWeight:300}}>ia</span>
      </MuiLoginText>
      <MuiLoginSubtitle variant="h6">
        {t('login_subtitle')}
      </MuiLoginSubtitle>
      <Grid>
        <MuiButton
          variant="outlined"
          fullWidth
          color="primary"
          size="large"
          disabled={isPending}
          startIcon={<img src='/social/google.svg' style={socialIcon}/> }
          style={socialButton}
          onClick={() => { loginSocial(providerCodes.GOOGLE); setActionTaken(actionTypes.LOGIN_SOCIAL_GOOGLE) }}>
          {isPending && actionTaken === actionTypes.LOGIN_SOCIAL_GOOGLE ? <CircularProgress style={{ color: 'black'}}  size={26} /> : 'Login with Google'}
        </MuiButton>

        <MuiButton
          variant="outlined"
          fullWidth
          color="primary"
          size="large"
          disabled={isPending}
          startIcon={<img src='/social/microsoft.svg' style={socialIcon}/>}
          style={socialButton}
          onClick={() => { loginSocial(providerCodes.MICROSOFT); setActionTaken(actionTypes.LOGIN_SOCIAL_MICROSOFT) }}>
          {isPending && actionTaken === actionTypes.LOGIN_SOCIAL_MICROSOFT ? <CircularProgress style={{ color: 'black'}}  size={26} /> : 'Login with Microsoft'}
        </MuiButton>
      </Grid>

      <p style={orStyle}><Typography style={orTextStyle}>{t('login_or')}</Typography></p>

      <FormProvider {...methods}>
        <form style={{width: '312px', marginLeft:'10px'}} onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3}>
            <MuiGridEmail item xs={12}>
              <TextField
                InputLabelProps={{
                  style: { color: 'rgba(50, 93, 108, 1)' },
                }}
                InputProps={{
                  style: { color: 'rgba(50, 93, 108, 1)' },
                  startAdornment: (
                  <InputAdornment position="start">
                   <MdOutlinePerson style={{color: '#6793A5', height: '24px'}}/>
                  </InputAdornment>
                ),
                }}
                sx={loginField}
                autoFocus
                variant='outlined'
                fullWidth
                label={t('email')}
                name='email'
                placeholder="email@example.com"
                autoComplete='email'
                error={t(errors.email?.message)}
              />
            </MuiGridEmail>
            <MuiLoginGrid item xs={12}>
                <TextField
                 InputLabelProps={{
                  style: { color: 'rgba(50, 93, 108, 1)' },
                }}
                InputProps={{
                  style: { color: 'rgba(50, 93, 108, 1)' },
                  startAdornment: (
                  <InputAdornment position="start" >
                    <MdLockOutline style={{color: '#6793A5', height:'24px'}}/>
                  </InputAdornment>
                ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleClickShowPassword}
                        edge="end">
                        {showPassword ? <Visibility style={{color: '#6793A5'}}/> : <VisibilityOff style={{color: '#6793A5'}} />}
                      </IconButton>
                    </InputAdornment>
                )}}
                  sx={{...loginField, marginTop:2}}
                  variant='outlined'
                  fullWidth
                  name='password'
                  placeholder="*******"
                  type={showPassword ? 'text' : 'password'}
                  autoComplete='current-password'
                  error={t(errors.password?.message)}
                  label={t('password')}
                />

            </MuiLoginGrid>
          </Grid>
          <Grid container style={{
            ...containerButtonsStyle,
            ...(errors.password?.message ? containerButtonsError : '')}}
          >
            <Grid item xs={12} style={containerButtonsStyle} >
              <span style={errorMessage}>{ error }</span>
            </Grid>
            <Grid item xs={12} style={containerButtonsStyle} >
              <MuiButtonForgot
                variant='text'
                component={NavLink}
                to='/forgot-password'
                data-cy="forgotPageLink"
              >
                {t('forgot_password')}
              </MuiButtonForgot>
            </Grid>
            <MuiLoginGrid item xs={12}>
              <MuiButtonSubmit
                loading={isPending && actionTaken === actionTypes.SUBMIT_FORM}
                isValid={isValid}
                type='submit'
                fullWidth
                data-cy='login'
                disabled={!isValid || isPending}
              >
                {isPending ? t('login...') : t('login')}
              </MuiButtonSubmit>
            </MuiLoginGrid>
          </Grid>
        </form>
      </FormProvider>
      <Dialog
        open={openDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{"Alert"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t('login_user_not_allowed')
              ? t('login_user_not_allowed')
              : 'The user is not register on the platform. Please contact support'
            }
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </MuiDivPaper>
  );
};
export default Login;
