import {
  Box, Button,
  Grid, IconButton, Slider, StepIcon,
  styled,
  ToggleButtonGroup,
  Typography
} from '@mui/material';
import { lighten } from '@mui/material/styles';
import {
  SelectField,
  SwitchField
} from '../../../../../../../common/ReactHooksFormFields';
import { GLOBAL } from '../../../../../../../../utils/constants';

export const MuiStepDescription = styled(Typography)(({ theme }) => ({
  marginTop: 7,
  fontFamily: 'Raleway',
  fontWeight: 400,
  fontSize: 20,
  marginBottom: 36
}));

export const MuiSlider = styled(Slider)(({ theme }) => ({
  '& .MuiSlider-thumb': {
    borderRadius: '4px',
    width: 4,
    height: 24,
    marginTop: '-2px',
    backgroundColor: theme.palette.primary.main,
  },
  '& .MuiSlider-track': {
    height: 2,
    opacity: 0.4,
    marginLeft: '-2px',
    marginTop: '-3px',
    color: theme.palette.primary.main,
  },
  '& .MuiSlider-rail': {
    height: '2px',
    backgroundColor: theme.palette.primary.main,
  },
  '& .MuiSlider-root': {
    padding: '13px 0',
  },
}));

export const MuiBtnClose = styled(Button)(({ theme }) => ({
  borderRadius: 20,
  color: GLOBAL.CUSTOM_PRIMARY_COLOR_PALETTE[900]
}));

export const MuiGridElementContainer = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(0, 1.5),
}));

export const MuiContainerLegendItems = styled(Grid)(({ theme }) => ({
  marginTop: 8,
  paddingTop:'8px !important',
  paddingLeft:16,
  paddingBottom:16,
  width:'100%',
  marginLeft: 0,
  height:'auto'
}));

export const MuiGridElement = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(0, 1.5,0,0),
}));

export const MuiBtnAction = styled(Button)(({ theme }) => ({
  padding:'10px 16px 10px 16px',
  borderRadius: '20px',
  color:'#005F64',
  borderColor:'#005F64',
  height:'37px',
  width:'105px',
  '&:hover': {
    borderColor:'#005F64',
  },
}));

export const MuiTypography = styled(Typography)(({ theme }) => ({
  fontSize:14,
  fontFamily:'Montserrat',
}));

export const MuiCustomSelectField = styled(SelectField, {
  shouldForwardProp: (prop) => prop !== 'type'
})(({ theme, type }) => ({
  marginTop: type === 'histogram' ? 41 : 0,
}));

export const MuiCustomStepIcon = styled(StepIcon)(({ theme }) => ({
  color: '#E0E0E0',
  '&.MuiStepIcon-root.Mui-completed': {
    color: theme.palette.primary.main,
  },
  '&.MuiStepIcon-root.Mui-active': {
    color: theme.palette.primary.main,
  },
}));

export const MuiBoxToggle = styled(Box)(({ theme }) => ({
  width: '365px',
  height: '32px',
  marginTop:8,
  border: `1px solid ${theme.palette.primary.main}`,
  backgroundColor: `${lighten(theme.palette.primary.main , 0.9)}`,
  borderRadius: '16px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}));

export const MuiSwitchField = styled(SwitchField,{
  shouldForwardProp: (prop) => prop !== 'calculator' && prop !== 'translation'
})(({ theme , calculator, translation}) => ({
  width: 153,
  height: 32,
  marginLeft: 8,
  padding: 0,
  display: 'flex',
  '&:active .MuiSwitch-thumb': {
    width: 67,
  },
  '&:active .MuiSwitch-switchBaseChecked': {
    transform: 'translateX(9px)',
  },
  '.MuiSwitch-switchBase': {
    color: theme.palette.primary.main,
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(74px)',
      color: theme.palette.primary.main,
      '& + .MuiSwitch-track': {
        border: '1px solid',
        background: lighten( theme.palette.primary.main, 0.9),
        borderColor: theme.palette.primary.main,
      },
    },
  },
  '.MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 67,
    height: 24,
    borderRadius: 12,
    marginTop: 2,
    marginLeft: 4,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
    '&:before': {
      content: !calculator ? `"${translation('columns')}"`
                           : `"${translation('formules')}"`,
      color: 'white',
      position: 'absolute',
      left: !calculator ? 12 : 15,
      top: '50%',
      transform: 'translateY(-50%)',
      fontSize: 10,
    },
  },
  '.MuiSwitch-track': {
    background: '#DFF2FC',
    opacity: 1,
    borderRadius: 20,
    border: '1px solid',
    borderColor: theme.palette.primary.main,
    backgroundColor: lighten( theme.palette.primary.main, 0.9),
    boxSizing: 'border-box',
    position: 'relative',
    '&:before': {
      content: `"${translation('columns')}"`,
      color: theme.palette.primary.main,
      position: 'absolute',
      left: 12,
      top: '50%',
      transform: 'translateY(-50%)',
      fontSize: 10,
    },
    '&:after': {
      content: `"${translation('formules')}"`,
      position: 'absolute',
      right: 15,
      top: '50%',
      transform: 'translateY(-50%)',
      fontSize: 10,
      color: theme.palette.primary.main,
    },
  },
  '.MuiSwitch-thumbChecked': {
    color: '#FFFFFF',
  },
}));

export const MuiTypographyVisuality = styled(Typography)(({ theme }) => ({
  marginTop:16,
  marginBottom:16,
  fontSize:16,
  fontWeight:400,
  fontFamily:'Raleway',
  color:'#000000'
}));

export const MuiTypographySortBy = styled(Typography)(({ theme }) => ({
  fontSize:12,
  fontWeight:400,
  fontFamily:'Montserrat',
  color:'#000000'
}));

export const MuiTypographyCategoryList = styled(Typography)(({ theme }) => ({
  fontSize:16,
  fontWeight:400,
  fontFamily:'Raleway',
  color:'#424242',
  marginBottom:10
}));

export const MuiTypographyCategory = styled(Typography)(({ theme }) => ({
  fontSize:14,
  fontWeight:500,
  fontFamily:'Montserrat',
  color:'#424242',
  marginTop:6,
  paddingLeft:10
}));

export const MuiTypographyToggle = styled(Typography)(({ theme }) => ({
  fontFamily:'Montserrat',
  fontWeight:400,
  fontSize:12,
  textTransform:'initial'
}));

export const MuiTypographyInfo = styled(Typography)(({ theme }) => ({
  fontSize:16,
  fontWeight:400,
  fontFamily:'Raleway',
  color:'#000000',
  marginBottom:24,
  marginTop:33
}));

export const MuiTypographyFormula = styled(Typography)(({ theme }) => ({
  marginLeft: -12,
  fontSize:20,
  fontWeight:400,
  fontFamily:'Raleway',
  textTransform:'uppercase',
  color:'#000000'
}));

export const MuiContainerSpecificInfo = styled('div')(({ theme }) => ({
  display:'flex',
  flexDirection:'column',
  width:'100%'
}));

export const MuiVisuality = styled('div')(({ theme }) => ({
  display:'flex',
  flexDirection:'column'
}));

export const MuiGridFormula = styled(Grid)(({ theme }) => ({
  display: 'flex',
  maxHeight: 40,
  alignItems: 'center',
}));

export const MuiCalculatorDisabled = styled(Grid)(({ theme }) => ({
  borderTop:'2px solid #E0E0E0',
  marginTop:'-2.7rem',
  height:'190px',
  pointerEvents: 'none',
  opacity: 0.5,
  backgroundColor: '#f5f5f5',
  color: '#9e9e9e'
}));

export const MuiGridCategory = styled(Grid)(({ theme }) => ({
  paddingTop:8,
  paddingBottom:8,
  borderBottom: '1px solid #E0E0E0'
}));

export const MuiIconButtonCommon = styled(IconButton)(({ theme }) => ({
  marginTop:25,
  marginLeft:16,
  color:'#9E9E9E',
  height:'16px',
  width:'16px'
}));

export const MuiTypographyLimit = styled(Typography)(({ theme }) => ({
  fontWeight:400,
  fontFamily:'Montserrat',
  fontSize:12,
  color:'#424242'
}));

export const MuiTypographyCommon = styled(Typography)(({ theme }) => ({
  fontFamily:'Raleway',
  fontWeight:400,
  fontSize:20,
  color:'#000000'
}));

export const MuiGridCategoryContainer = styled(Grid)(({ theme }) => ({
  backgroundColor:'#FAFAFA',
  borderRadius:12,
  marginRight:10
}));

export const MuiToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  '& .MuiToggleButtonGroup-grouped':{
    color:theme.palette.primary.main,
    width: '120px',
    height: '25px',
    marginTop:0.3,
    border:0,
    '&:hover': {
      backgroundColor: `${lighten(theme.palette.primary.main, 0.9)}`
    }
  },
  '& .Mui-selected':{
    color:'white !important',
    width:'115px',
    height:'24px',
    backgroundColor:`${theme.palette.primary.main} !important`,
    borderRadius:12,
  },
  border: 0,
  color: 'red',
  '&:hover': {
    backgroundColor: `${lighten(theme.palette.primary.main, 0.9)}`
  }
}));

export const stepLabelStyle = {
  cursor: 'pointer',
  textTransform:'uppercase',
  fontFamily:'Montserrat',
  fontWeight:500,
  fontSize:14,
}

export const stepperStyle = {
  paddingLeft: 0,
}

export const inputStyle = {
  '& .MuiInputBase-root': {
    height: 56,
    fontFamily: 'Montserrat',
    fontWeight: 400,
    fontSize: '16px',
  },
  '& input': {
    color: '#424242',
    fontSize:16,
    fontFamily:'Montserrat'
  },
  '& legend': {
    fontSize:9,
  },
}

export const inputAreaStyle = {
  '& .MuiInputBase-root': {
    height: 112,
    fontFamily: 'Montserrat',
    fontWeight: 400,
    fontSize: '16px',
  },
  '& textarea': {
    color: '#424242',
    fontSize:16,
    fontFamily:'Montserrat'
  },
}

export const selectSkeleton = {
  marginTop: -16,
}

export const stepButtonStyle = {
  color: '#E0E0E0',
  '& text': {
    fill: '#FFFFFF',
  },
}

export const labelInputStyle = {
  color: '#424242',
  fontSize:12,
  fontFamily:'Montserrat',
}

export const inputLimitStyle = {
  marginTop: '16px',
  marginLeft: '12px',
  width:'80px'
}

export const commonStyle = {
  height:'56px',
  width:'123px',
  '& .MuiInputLabel-root':{
    fontFamily:'Montserrat'
  },
}

export const iconButtonStyle = {
  marginTop:20,
  marginLeft:3
}

export const adornmentColorStyle = {
  color: '#9E9E9E',
  marginTop:'-1px'
}

export const inputColorCommonStyle = {
  fontFamily: 'Montserrat',
  color: '#424242',
  fontSize: '16px'
}

export const adornmentPercentStyle = {
  color: '#9E9E9E',
  marginTop:'-2px',
  marginLeft:'-4.2rem',
  fontFamily:'Montserrat',
  fontWeight: 400
}

export const labelSelectStyle = {
  marginTop:'5px',
  color: '#424242',
  fontSize:12,
  fontFamily:'Montserrat'
}

export const inputSelectStyle = {
  '& .MuiInputBase-input': {
    color: '#424242',
    fontSize:16,
    fontFamily:'Montserrat'
  },
  '& legend': {
    fontSize:9,
  },
}
