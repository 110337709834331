import { colors } from '@material-ui/core';
// External library imports
import {styled, Table, TablePagination, Grid, Skeleton, TextField } from '@mui/material';
import AlarmOffIcon from '@mui/icons-material/AlarmOff';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import PublicIcon from '@mui/icons-material/Public';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';

// Internal component and function imports
import EditIcon from '../EditIcon';
import {lighten} from "@mui/material/styles";
import {DatePicker} from "@mui/x-date-pickers";
import { GLOBAL } from '../../../../../utils/constants';

export const MuiTableBase = styled(Table)(({ theme }) => ({
  minWidth: 650,
  fontFamily: 'Montserrat'
}));

export const MuiGridContainerButtons = styled(Grid)(({ theme }) => ({
  minWidth: 60,
  maxWidth: '100%',
  justifyContent: 'flex-center',
  gap: '10px'
}));

export const MuiButtonSkeleton = styled(Skeleton)(({ theme }) => ({
  width: 60,
}));

export const MuiForwardIcon = styled(PublicIcon)(({ theme }) => ({
  color: localStorage.getItem('colorPrimary'),
  width: '34px',
  height: '34px',
  minWidth: 25,
  padding: 4,
  borderRadius:'50%',
  border: `1px solid ${lighten(localStorage.getItem('colorPrimary'), 0.6)}`,
  align: 'center',
  cursor:'pointer',
  '&:hover': {
    backgroundColor: lighten(GLOBAL.COLOR_THEME.PRIMARY, 0.9),
  },
}));

export const MuiEditIcon = styled(EditIcon)(({ theme }) => ({
  color: localStorage.getItem('colorPrimary'),
  minWidth: 25,
  width: '34px',
  height: '34px',
  padding: 4,
  border: `1px solid ${lighten(localStorage.getItem('colorPrimary'), 0.6)}`,
  borderRadius:'50%',
  align: 'center',
  '&:hover': {
    backgroundColor: lighten(GLOBAL.COLOR_THEME.PRIMARY, 0.9),
  },
}));

export const MuiSettingIcon = styled(SettingsOutlinedIcon)(({ theme }) => ({
  color: localStorage.getItem('colorPrimary'),
  minWidth: 25,
  padding: 4,
  width: '34px',
  height: '34px',
  border: `1px solid ${lighten(localStorage.getItem('colorPrimary'), 0.6)}`,
  borderRadius:'50%',
  align: 'center',
  cursor:'pointer',
  '&:hover': {
    backgroundColor: lighten(GLOBAL.COLOR_THEME.PRIMARY, 0.9),
  },
}));

export const MuiDeleteIcon = styled(DeleteOutlinedIcon)(({ theme }) => ({
  color: '#F63B2B',
  minWidth: 25,
  width: '34px',
  height: '34px',
  padding: 4,
  borderRadius:'50%',
  border: `1px solid ${lighten('#F63B2B', 0.6)}`,
  align: 'center',
  cursor:'pointer',
  '&:hover': {
    backgroundColor: lighten('#F63B2B', 0.9),
  },
  backgroundColor: 'transparent'
}));

export const MuiAlarmOffIcon = styled(AlarmOffIcon)(({ theme }) => ({
  color: '#212121',
  width: '34px',
  height: '34px',
  padding: 3,
  marginLeft: theme.spacing(3),
  align: 'center',
}));

export const MuiTextField = styled(TextField)(({ theme }) => ({
  marginBottom: theme.spacing(0),
  '& .MuiInputBase-root': {
    height: '28px',
    padding: '5px 8px 5px 8px',
    borderRadius:'4px',
    borderColor:'#757575',
    fontSize: '10px',
  },
  '& .MuiInputBase-input': {
    paddingLeft: '8px',
  },
  '& input::placeholder': {
    fontSize: '10px',
    textTransform: 'uppercase',
    fontFamily:'Montserrat',
  },
  '& input': {
    fontFamily:'Montserrat',
  },
}));

export const MuiTablePagination = styled(TablePagination)(({ theme }) => ({
  '& .MuiTablePagination-selectLabel': {
    fontSize:12,
    fontFamily:'Montserrat',
  },'& .MuiSelect-select': {
    fontSize:12,
    fontFamily:'Montserrat',
  },'& .MuiTablePagination-displayedRows': {
    fontSize:12,
    fontFamily:'Montserrat',
  },'& .MuiToolbar-root': {
    paddingLeft:'0px',
    display: 'flex',
    justifyContent: 'flex-start'
  }
}));

export const MuiDatePicker = styled(DatePicker)(({ theme }) => ({
  width: 130,
  marginLeft:'-10px',
  '& .MuiInputBase-root': {
    height: '28px',
    borderRadius:'4px',
  },'& .MuiOutlinedInput-notchedOutline': {
    borderColor:'#BDBDBD',
    borderRight:'none',
  },'& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: '#BDBDBD',
  },
  '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: '#BDBDBD',
  },
  '& .MuiInputLabel-root': {
    color: '#BDBDBD',
    marginTop: '-5px',
    fontSize: '10px',
    textTransform: 'uppercase',
    fontFamily:'Montserrat',
    letterSpacing:'1.5px'
  },
  '& .MuiInputLabel-root.Mui-focused': {
    top: '0px !important',
  },
  '& .MuiSvgIcon-root': {
    fontSize: '18px',
  },
  '& .MuiButtonBase-root:hover': {
    backgroundColor: 'transparent'
  },
  '& input': {
    fontSize: '10px',
    fontFamily:'Montserrat',
  },
}));

export const tableCellActionsStyle = {
  padding: '0px !important',
  height: '50px',
}

export const gridStyle = {
  display: 'none'
}
export const fuenteStyle={
  fontFamily: 'Montserrat'
}

export const rowFilteredStyle={
  fontSize: '10px',
  height: '43px !important',
  padding: '0px !important',
}

export const actionsComponentStyle = {
  display: 'flex',
  justifyContent: 'flex-end',
  marginRight:'650px'
}
export const demoContainerStyle = {
  width: '250px !important',
  paddingTop:'0px',
  overflow:'none'
}

export const paginationActionsStyle = {
  display: 'flex',
  justifyContent: 'flex-start',
  paddingLeft: '0px !important'
}
export const cellStyle = {
  padding: '0px !important',
  height: '50px !important',
  wordWrap: 'break-word',
  whiteSpace: 'normal',
}
