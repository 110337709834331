import { Fab, Grid, styled } from '@mui/material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

const drawerWidth = 350;

export const MuiNav = styled('nav')(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    width: drawerWidth,
    flexShrink: 0,
  },
}));

export const MuiExpandLessIcon = styled(ExpandLessIcon)(({ theme }) => ({
  color: theme.palette.text.hint,
  height: theme.spacing(4),
  transform: 'rotate(180deg)',
}));

export const MuiDiv = styled('div')(({ theme }) => ({
  minHeight: theme.spacing(18),
  '& > *': {
    paddingBottom: theme.spacing(6),
  },
}));

export const MuiImg = styled('img')(({ theme }) => ({
  position: 'absolute',
  bottom: theme.spacing(4),
  left: '50%',
  transform: 'translateX(-50%)',

  [theme.breakpoints.down('sm')]: {
    bottom: theme.spacing(4.75),
  },

  [theme.breakpoints.down('xs')]: {
    bottom: theme.spacing(13.5),
  },
}));

export const MuiFab = styled(Fab)(({ theme, bottomSheetOpen }) => ({
  position: 'absolute',
  bottom: theme.spacing(5),
  right: theme.spacing(2),
  zIndex: theme.zIndex.drawer + 1,
  color: theme.palette.primary.main,
  backgroundColor: theme.palette.common.white,
  transform: `translateY(${bottomSheetOpen ? 0 : theme.spacing(3)}px)`,
  transition: `transform ${theme.transitions.easing.sharp} ${theme.transitions.duration.shortest}ms`,

  '&:hover': {
    backgroundColor: theme.palette.common.white,
  },

  '& .MuiFab-label': {
    width: theme.spacing(9),
    justifyContent: 'flex-start',
  },

  '& .MuiSvgIcon-root': {
    transform: bottomSheetOpen ? 'rotate(0)' : 'rotate(-180deg)',
    transition: `transform ${theme.transitions.easing.sharp} ${theme.transitions.duration.shortest}ms`,
  },
}));

export const MuiGrid = styled(Grid, {
  shouldForwardProp: (prop) => prop !== 'isGmaps'
})(({ theme, isGmaps }) => ({
  position: 'relative',
  flex: 1,
  overflow: 'hidden',

  // Fix Mapbox attribution button not clickable
  '& #deckgl-wrapper': {
    '& #deckgl-overlay': {
      zIndex: 1,
    },
    '& #view-default-view > div': {
      zIndex: 'auto !important',
    },
  },
  '& $zoomControl': {
    left: isGmaps ? theme.spacing(4) : '',
    bottom: isGmaps ? theme.spacing(5) : '',
  },
}));

export const drawerPaper = {
  width: drawerWidth,
}

export const bottomSheet = {
  maxHeight: `calc(100% - 36px)`,

  '&$closed': {
    transform: `translateY(calc(100% - 72px)) !important`,
    visibility: 'visible !important',

    '& $bottomSheetContent': {
      overflow: 'hidden',
    },
  },
}

export const closed = {}
